(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("single-spa-angular"), require("@angular/common"), require("@imunify/nav-root"), require("@angular/material/checkbox"), require("@angular/material/input"), require("@angular/forms"), require("@angular/material/radio"), require("@angular/cdk/collections"), require("@angular/cdk/scrolling"), require("@angular/cdk/a11y"), require("@angular/cdk/overlay"), require("@angular/material/slide-toggle"), require("@angular/cdk/observers"), require("@angular/animations"), require("@angular/cdk/keycodes"), require("@angular/material/menu"), require("@angular/material/form-field"), require("@angular/cdk/portal"), require("@angular/router"), require("@angular/material/select"), require("@angular/material/button"), require("@angular/cdk/text-field"), require("@angular/material/slider"), require("@angular/material/progress-spinner"), require("@angular/material/autocomplete"), require("rxjs"), require("@angular/platform-browser/animations"), require("@angular/material/tooltip"), require("rxjs/operators"), require("@angular/material/progress-bar"), require("@angular/material/core"), require("@angular/material/dialog"), require("@angular/common/http"), require("@angular/cdk/layout"), require("@angular/cdk/platform"), require("@angular/cdk/bidi"), require("@angular/animations/browser"), require("@angular/platform-browser"), require("@angular/core"), require("@angular/material/icon"), require("@angular/material/chips"));
	else if(typeof define === 'function' && define.amd)
		define(["single-spa-angular", "@angular/common", "@imunify/nav-root", "@angular/material/checkbox", "@angular/material/input", "@angular/forms", "@angular/material/radio", "@angular/cdk/collections", "@angular/cdk/scrolling", "@angular/cdk/a11y", "@angular/cdk/overlay", "@angular/material/slide-toggle", "@angular/cdk/observers", "@angular/animations", "@angular/cdk/keycodes", "@angular/material/menu", "@angular/material/form-field", "@angular/cdk/portal", "@angular/router", "@angular/material/select", "@angular/material/button", "@angular/cdk/text-field", "@angular/material/slider", "@angular/material/progress-spinner", "@angular/material/autocomplete", "rxjs", "@angular/platform-browser/animations", "@angular/material/tooltip", "rxjs/operators", "@angular/material/progress-bar", "@angular/material/core", "@angular/material/dialog", "@angular/common/http", "@angular/cdk/layout", "@angular/cdk/platform", "@angular/cdk/bidi", "@angular/animations/browser", "@angular/platform-browser", "@angular/core", "@angular/material/icon", "@angular/material/chips"], factory);
	else if(typeof exports === 'object')
		exports["other-root"] = factory(require("single-spa-angular"), require("@angular/common"), require("@imunify/nav-root"), require("@angular/material/checkbox"), require("@angular/material/input"), require("@angular/forms"), require("@angular/material/radio"), require("@angular/cdk/collections"), require("@angular/cdk/scrolling"), require("@angular/cdk/a11y"), require("@angular/cdk/overlay"), require("@angular/material/slide-toggle"), require("@angular/cdk/observers"), require("@angular/animations"), require("@angular/cdk/keycodes"), require("@angular/material/menu"), require("@angular/material/form-field"), require("@angular/cdk/portal"), require("@angular/router"), require("@angular/material/select"), require("@angular/material/button"), require("@angular/cdk/text-field"), require("@angular/material/slider"), require("@angular/material/progress-spinner"), require("@angular/material/autocomplete"), require("rxjs"), require("@angular/platform-browser/animations"), require("@angular/material/tooltip"), require("rxjs/operators"), require("@angular/material/progress-bar"), require("@angular/material/core"), require("@angular/material/dialog"), require("@angular/common/http"), require("@angular/cdk/layout"), require("@angular/cdk/platform"), require("@angular/cdk/bidi"), require("@angular/animations/browser"), require("@angular/platform-browser"), require("@angular/core"), require("@angular/material/icon"), require("@angular/material/chips"));
	else
		root["other-root"] = factory(root["single-spa-angular"], root["@angular/common"], root["@imunify/nav-root"], root["@angular/material/checkbox"], root["@angular/material/input"], root["@angular/forms"], root["@angular/material/radio"], root["@angular/cdk/collections"], root["@angular/cdk/scrolling"], root["@angular/cdk/a11y"], root["@angular/cdk/overlay"], root["@angular/material/slide-toggle"], root["@angular/cdk/observers"], root["@angular/animations"], root["@angular/cdk/keycodes"], root["@angular/material/menu"], root["@angular/material/form-field"], root["@angular/cdk/portal"], root["@angular/router"], root["@angular/material/select"], root["@angular/material/button"], root["@angular/cdk/text-field"], root["@angular/material/slider"], root["@angular/material/progress-spinner"], root["@angular/material/autocomplete"], root["rxjs"], root["@angular/platform-browser/animations"], root["@angular/material/tooltip"], root["rxjs/operators"], root["@angular/material/progress-bar"], root["@angular/material/core"], root["@angular/material/dialog"], root["@angular/common/http"], root["@angular/cdk/layout"], root["@angular/cdk/platform"], root["@angular/cdk/bidi"], root["@angular/animations/browser"], root["@angular/platform-browser"], root["@angular/core"], root["@angular/material/icon"], root["@angular/material/chips"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__aQr__, __WEBPACK_EXTERNAL_MODULE__0S4P__, __WEBPACK_EXTERNAL_MODULE__0xy7__, __WEBPACK_EXTERNAL_MODULE__2caw__, __WEBPACK_EXTERNAL_MODULE__3SZY__, __WEBPACK_EXTERNAL_MODULE__3xDq__, __WEBPACK_EXTERNAL_MODULE__4Oyg__, __WEBPACK_EXTERNAL_MODULE__573b__, __WEBPACK_EXTERNAL_MODULE__7zYK__, __WEBPACK_EXTERNAL_MODULE__7zk3__, __WEBPACK_EXTERNAL_MODULE__8Yb7__, __WEBPACK_EXTERNAL_MODULE__8ZSF__, __WEBPACK_EXTERNAL_MODULE__9Ka3__, __WEBPACK_EXTERNAL_MODULE__9gj6__, __WEBPACK_EXTERNAL_MODULE_BALj__, __WEBPACK_EXTERNAL_MODULE_D7y7__, __WEBPACK_EXTERNAL_MODULE_EdK2__, __WEBPACK_EXTERNAL_MODULE_IGV0__, __WEBPACK_EXTERNAL_MODULE_JAnZ__, __WEBPACK_EXTERNAL_MODULE_L2qU__, __WEBPACK_EXTERNAL_MODULE_MdUB__, __WEBPACK_EXTERNAL_MODULE_NwIF__, __WEBPACK_EXTERNAL_MODULE_Q5cP__, __WEBPACK_EXTERNAL_MODULE_S9u3__, __WEBPACK_EXTERNAL_MODULE_UREi__, __WEBPACK_EXTERNAL_MODULE_Vgaj__, __WEBPACK_EXTERNAL_MODULE_Xwin__, __WEBPACK_EXTERNAL_MODULE_YYwv__, __WEBPACK_EXTERNAL_MODULE_aP7H__, __WEBPACK_EXTERNAL_MODULE_aqnc__, __WEBPACK_EXTERNAL_MODULE_c5Cn__, __WEBPACK_EXTERNAL_MODULE_h8Zy__, __WEBPACK_EXTERNAL_MODULE_jyyq__, __WEBPACK_EXTERNAL_MODULE_l9L7__, __WEBPACK_EXTERNAL_MODULE_lVjo__, __WEBPACK_EXTERNAL_MODULE_sE19__, __WEBPACK_EXTERNAL_MODULE_v5i9__, __WEBPACK_EXTERNAL_MODULE_vG_p__, __WEBPACK_EXTERNAL_MODULE_vOrQ__, __WEBPACK_EXTERNAL_MODULE_vbq7__, __WEBPACK_EXTERNAL_MODULE_yHl9__) {
return 